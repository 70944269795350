import ProvideAuth from "./context/provideAuth";
import {lazy, Suspense} from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Spinner from './components/spinner';

const PublicLayout = lazy(() => import('./layouts/public'));
const PrivateLayout = lazy(() => import('./layouts/private'));

function App() {
    return (
        <div className="App">
            <ProvideAuth>
                <Router>
                    <Suspense fallback={<Spinner animation="border"/>}>
                        <Routes>
                            <Route path="/admin/*" element={<PrivateLayout/>} />
                            <Route path="*" element={<PublicLayout/>} />
                        </Routes>
                    </Suspense>
                </Router>
            </ProvideAuth>
        </div>
    );
}

export default App;
