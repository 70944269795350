import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.scss';
import App from './App';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'

import projectStorage from './reducers/rootReducer'
import Spinner from "./components/spinner";

const container = document.getElementById('root');
const root = createRoot(container);

if (process.env.IS_SENTRY) Sentry.init({
    dsn: "https://f640f2d4c6dd4b3cb30234b49abbac5b@o1093233.ingest.sentry.io/6612922",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

root.render(<React.StrictMode>
    <Provider store={projectStorage.store}>
        <PersistGate loading={<Spinner />} persistor={projectStorage.persistor}>
            <App/>
        </PersistGate>
    </Provider>
</React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
