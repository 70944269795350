let defaultState = { user: null, token: null};

const userReducer = function(state = defaultState, action) {
    switch (action.type) {
        case 'LOGIN':
            let newUser = Object.assign({}, action.payload);
            return newUser;
        case 'LOGOUT':
            let newDefault = defaultState;
            return newDefault;
        default:
            return state;
    }
};

export default userReducer;
